<template>
  <FormWrapper
    :form="form"
    :hideForm="() => handleProps({ key: 'showing', value: false })"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row>
            <b-col cols="10">
              <div class="form-group">
                <validation-provider
                  name="cid10"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-model="fields.cid"
                    id="cid"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    @search-change="searchCids"
                    placeholder="Digite aqui para buscar..."
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noOptions"
                      >Digite para pesquisar um CID10
                    </template>
                    <template slot="noResult">Nenhum CID10 encontrado</template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 button"
                :disabled="invalid"
                @click="saveItem"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th>CID adicionados</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td>
                    {{ value.cid }}
                    <span class="tag-predominante" v-if="value.predominante">
                      predominante
                    </span>
                  </td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <div
                          class="
                            w-100
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-4
                          "
                        >
                          <span class="mr-29">Predominante</span>
                          <toggle-button
                            v-model="value.predominante"
                            :sync="true"
                            :height="32"
                            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                            :disabled="!canEdit"
                            @change="checkPredominant(index)"
                          />
                        </div>
                        <b-button
                          v-if="!value.predominante && form.value.length"
                          variant="link remove mb-0 p-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { debounce } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  components: {
    FormWrapper,
    ChevronDown,
    MoreVertical
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.cid,
    }),
    ...mapGetters('attendance', ['cidConsultation', 'attendance'])
  },
  data() {
    return {
      isLoading: false,
      searchCids: Function,
      options: [],
      editingIndex: null,
      fields: this.getDefaultFields(),
      user: getCurrentUser(),
    }
  },
  mounted() {
    this.searchCids = debounce(this.searchCid10, 300);
  },
  watch: {
    'cidConsultation': function (value) {
      if(value){
        this.setCid()
      }
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord']),
    ...mapActions('attendance/form/cid', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        cid: null,
        predominante: false
      }
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.antecedente = value ? searchTerm : null
    },
    async setCid(){   

      const value = await [...this.form.value]  
      const lastCid = this.cidConsultation?.medical_records?.find(el => el.type === 'cid')
      if(this.attendance?.type === 'BV_EXAM'){
        value.push({
          cid: "Z000 - Exame médico geral",
          predominante: !value.some(el => el.predominante),
        }) 
        this.handleProps({ key: 'value', value })
        this.updateMedicalRecord(this.form)
      }
      
      else if(!value.length && this.canEdit && lastCid && this.cidConsultation?.professional_id === this.user.id){
        lastCid.value.forEach(item => {
          value.push({
            cid: item.cid,
            predominante: item.predominante,
          })  
        })
        this.handleProps({ key: 'value', value })
        this.updateMedicalRecord(this.form)
      }
    },
    saveItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push({
        ...this.fields,
        predominante: !value.some(el => el.predominante)
      })
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
      this.onChange()
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.onChange()
    },
    checkPredominant(index) {
      const value = [...this.form.value]
      value.map((v, i) => (v.predominante = i === index))
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    async searchCid10(query) {
      if (!query) return
      this.isLoading = true
      try {
        const response = await this.api.searchCid10({ term: query })
        this.options = response.data.map(cid10 => {
          return `${cid10.code} - ${cid10.description}`
        })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$toast.error(error.message)
      }
    },
    onChange() {
      const value = [...this.form.value]
      value.unshift('Não informar')
      if (!value.includes(this.fields.cid)) {
        this.fields.cid = null
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 24px;
}
</style>
